/*
full-layout

@copyright      Copyright 2015, AceWare, Inc. (http://www.aceware.co.jp)
*/
/* 
    Created on : 2015/02/19, 17:45:09
    Author     : Takayuki Shinbo <t.shinbo@aceware.co.jp>
*/
@import "bootstrap/variables.less";
@import "bootstrap/mixins.less";

html, body {
    padding-top: 0;
    height: 100%;
    background-color: #2B2B2B;
    #gradient > .vertical(@start-color: #2B2B2B; @end-color: #4A4A4A);
    background-attachment: fixed !important;
}

// bland logo
#bland-logo {
    position: fixed;
    top: 15px;
    left: 30px;
    color: #fff;
    font-size: 48px;
    text-shadow: 0 1px 3px rgba(0,0,0,.5);
    font-family: "Roboto Thin";
}

@media (max-width: 750px) {
    #bland-logo {
        display: none;
    }
}

.wrapper {
    height: 100%;
}

// Signin
.signin-wrapper {
    display: table;
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.signin-wrapper-inner {
    display: table-cell;
    vertical-align: middle;
}

.signin-wrapper .page-header {
    margin-top: 10px;
}

.signin-wrapper .page-header {
    font-family: "Roboto Thin";
}

.signin-wrapper-inner .panel {
    z-index: 9999;
}

.signin-wrapper-inner .panel .panel-body {
    .box-shadow(0px 0px 30px 30px darken(@panel-default-heading-bg, 2%) inset);
    border-radius: @panel-border-radius;
}